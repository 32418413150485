/* Estilos gerais para o footer */
.footer {
  background-color: #1C68EB; /* Cor do fundo do rodapé */
  color: white;
  padding: 20px 0;
  display: flex; /* Ativa o Flexbox */
  justify-content: space-between; /* Espaça os elementos igualmente */
  align-items: center; /* Alinha os elementos verticalmente */
  flex-wrap: wrap; /* Garante que os itens quebrem linha em telas pequenas */
  gap: 20px; /* Espaçamento entre os itens */
}

.footer-logo img {
  max-width: 90px;
  margin-right: 30px;
}

.footer-info {
  text-align: center;
}

.footer-info h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.footer-info p {
  margin: 5px 0;
}

.footer-social {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: auto;
}

.footer-rights {
  background-color: #1C68EB;
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  flex: 1 1 100%;
  display: flex;
  justify-content: space-around;
}

.footer-rights .privacy-policy {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer-rights .privacy-policy:hover {
  opacity: 0.7;
}

.sub-footer{
  display: flex;
      width: 100%;
      height: auto;
      align-content: center;
      align-items: center;
      justify-content: center;
      gap: 7%;
}

/* Responsividade */
@media (max-width: 768px) {
  .footer, .sub-footer {
    flex-direction: column; /* Alinha os itens em coluna em telas pequenas */
    gap: 15px;
  }

  .footer-rights {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }
  
}
