/* src/styles/Container.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%; /* Limita o conteúdo a 70% da largura da tela */
    max-width: 1200px; /* Limita o tamanho máximo */
    margin: 0 auto; /* Centraliza horizontalmente */
    padding: 20px;
    background-color: #f5f7fa; /* Fundo  */
    border-radius: 8px; /* Bordas arredondadas */
  }

  @media (max-width: 568px) {
    .container {
        display: flex;
        margin: 0px;
        width: 100vw;
        padding: 0px;
      }
  }
  