/* src/styles/Contact.css */
.contact-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 20px;
  background-color: #f7f9fc;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 55px;
}

.contact-text {
  width: 50%;
  padding-right: 0px;
}

.contact-text h2 {
  font-size: 32px;
  margin-bottom: 10px;
  color: #0056b3;
}

.contact-text p {
  font-size: 1.0rem;
  color: #555;
}

/* Estilo para o mapa */
.map-container {
  margin-top: 20px;
  width: 100%;
}

.contact-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 31px;
}

.contact-form label {
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.contact-form input, .contact-form textarea {
  padding: 10px;
  margin-top: 5px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form textarea {
  resize: vertical;
  min-height: 120px;
}

.terms-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.terms-checkbox-container input {
  margin-right: 0px;
  width: 20%;
}

.contact-form button{
  margin-top: 0px !important;
}

button {
  padding: 12px 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #0056b3;
}

.reset-button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
}

.reset-button:hover {
  background-color: #e6e6e6;
}

/* Responsividade */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .map-container{
    display: none;
  }

  .contact-text {
    width: 100%;
    text-align: center;
  }

  .contact-form {
    width: 100%;
  }

  .terms-checkbox-container input {
    margin: 5%;
    width: auto;
    /* padding: 10%; */
  }

  .terms-checkbox-container{
    margin-bottom: 0px;
  }

    .contact-form label{
      font-size: 14px;
    }

    button{
      margin-top: 10px;
    }
}
