/* src/styles/Services.css */
.services {
  padding: 40px 20px; /* Maior espaçamento para dar um ar mais amplo */
  font-family: 'Poppins', sans-serif; /* Usar a fonte Poppins para modernidade */
  background-color: #f7f9fc; /* Fundo mais claro para a área de serviços */
  color: #333; /* Cor mais escura para o texto */
}

.services h1 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
  color: #0056b3; /* Azul para o título */
}

.services p {
  font-size: 18px;
  color: #555;
  font-weight: 300;
  margin-bottom: 40px;
  text-align: center;
}

.services-list {
  display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px 30px;
    justify-content: center;
}

.service-item {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 calc(33.333% - 20px); /* Garante que os itens ocupem a largura necessária */
  max-width: calc(33.333% - 20px); /* Limita a largura máxima */
  display: flex;
  flex-direction: column; /* Alinha os elementos verticalmente */
  justify-content: space-between; /* Cria espaçamento uniforme */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
  transform: translateY(-10px); /* Efeito de elevação mais visível */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Aumenta a sombra ao passar o mouse */
}

.service-item h2 {
  font-size: 1.3rem;
  color: #007bff; /* Azul para os títulos das seções de serviço */
  margin-bottom: 15px;
  font-weight: 600; /* Peso mais forte para os subtítulos */
}

.service-item p {
  font-size: 1rem;
  color: #666; /* Cor de texto mais suave para descrição */
  margin-bottom: 25px; /* Maior espaçamento abaixo do texto */
}

.view-photos-button {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.view-photos-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .services-list {
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

  .service-item {
    flex: 1 1 100%; /* Ocupa 100% da largura */
    max-width: 100%; /* Garante largura total em dispositivos menores */
  }
}
