/* src/styles/Gallery.css */

/* Estilização da galeria principal */
.gallery {
  padding: 40px 20px;
  font-family: 'Poppins', sans-serif;
  background-color: #f7f9fc; /* Fundo claro e suave */
  color: #333;
}

.gallery h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #0056b3; /* Azul suave */
  margin-bottom: 30px;
}

.gallery-images {
  display: flex;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
}

/* Cartões de imagem */
.image-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 30%;
}

.image-card:hover {
  transform: translateY(-10px); /* Leve elevação */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Descrição da imagem */
.image-description {
  margin-top: 20px;
  font-size: 1rem;
  color: #555;
}

.image-description p {
  margin: 8px 0;
}

/* Estilização do Swiper */
.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-image img {
  margin-top: 15px;
  max-width: 100%;
  height: 170px;
  max-height: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.carousel-image img:hover {
  transform: none /* Leve zoom ao passar o mouse */
}

.carousel-image p {
  margin: 10px 0px;
  font-weight: bold;
  color: #333;
}

/* Botões de navegação do Swiper */
.swiper-button-next,
.swiper-button-prev {
  color: #007bff;
    display: flex;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    width: 40px;
    padding: 0px;
    transition: background 0.3s ease, transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: #007bff; /* Azul sólido ao passar o mouse */
  color: white; /* Setas brancas para contraste */
  transform: scale(1.1); /* Aumenta levemente o tamanho */
}

.swiper-button-prev:after, .swiper-button-next:after{
  font-size: 28px !important;
}

/* Responsividade */
@media (max-width: 768px) {
  .gallery h1 {
    font-size: 2rem;
  }

  .image-card {
    padding: 15px;
  }

  .image-description p {
    font-size: 0.9rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .gallery h1 {
    font-size: 1.8rem;
  }

  .image-card {
    padding: 10px;
  }

  .image-description p {
    font-size: 0.85rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    padding: 6px;
  }
}
