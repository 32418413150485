@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&family=Roboto+Slab:wght@400;700&display=swap');
/* Slick Carousel CSS */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*{
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: 'Poppins', sans-serif; /* Fonte principal */
  font-weight: 400; /* Peso padrão para o texto */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f7fa;
  
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Slab', serif; /* Fonte para títulos */
  font-weight: 700; /* Peso mais forte para destaque */
}

p, span, li, a {
  font-family: 'Poppins', sans-serif; /* Fonte principal */
  font-weight: 300; /* Peso mais leve para texto secundário */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
